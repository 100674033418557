import {
  useState
} from 'react'
  
import {
  Button,
  Card,
  CardBody,
  Select,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'

import { baseUrl } from '../env'
import dayjs from '../dayjs'

import type { IsoTime } from '../types/branded'
import type { TaskOccurrence } from '../types/models'

import 'react-datepicker/dist/react-datepicker.css'

export default function AdminDelayForm({ taskOccurrences }: { taskOccurrences: TaskOccurrence[] }) {
    const [deadline, setDeadline] = useState<IsoTime|null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [didSucceed, setDidSucceed] = useState(false)
    const [didError, setDidError] = useState(false)

    const filteredOccurrences = taskOccurrences.filter(occurrence =>
      !occurrence.completedAt || occurrence.failedAt ||
      occurrence.resolutionState !== 'retcon'
    )

    const handleButtonPress = async () => {
      setIsLoading(true)
      const response = await fetch(`${baseUrl}/handlers/delay`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            occurrenceId: (document.getElementById('occurrence') as HTMLSelectElement).value,
            deadline: deadline!
          })
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
        } else {
            setDidError(true)
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
      <Card>
        <CardBody>
        <Select id='occurrence'>
            <option value=''>Select an occurrence</option>
            {filteredOccurrences.map(occurrence => (
                <option
                  key={occurrence.id}
                  value={occurrence.id}
                >
                  {`${occurrence.task.name} (${dayjs(occurrence.date).format('ddd, M/D')})${occurrence.resolutionState !== 'pending' ? ` - ${occurrence.resolutionState}` : ''}`}
                </option>
            ))}
        </Select>
          New task time:<br />
          <DatePicker
              selected={deadline ? new Date(deadline) : null}
              onChange={(dateObj) => {
                setDeadline(dateObj!.toISOString() as IsoTime)
              }}
              dateFormat="MMMM d, yyyy h:mm aa"
              showTimeInput
              inline
          />
        </CardBody>
        <Button
          isDisabled={!deadline}
          isLoading={isLoading}
          colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
          onClick={handleButtonPress}
        >
          {didSucceed ? 'Delayed task' : didError ? 'Failed to delay task' : 'Delay task' }
        </Button>
      </Card>
    )
}