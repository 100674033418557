import { StatsData } from '../types/other'

export default function organizeRewards(rewards: StatsData.Reward[]) {
    const groups = [] as [string, StatsData.Reward[]][]
    for (const reward of rewards) {
        const groupName = categorizeReward(reward)
        const group = groups.find(([name]) => name === groupName)
        if (group) {
            group[1].push(reward)
        } else {
            groups.push([groupName, [reward]])
        }
    }
    
    return groups.sort((a, b) => b[1].length - a[1].length)
    .map(([name, rewards]) => {
        return [
            name,
            rewards.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        ] as [string, StatsData.Reward[]]
    })
}

const artRewards = [
    'watercolors',
    'iprc prints',
    'watercolor block',
    'set of technical pens'
]
const foodRewards = [
    'chocolate treat at saic on 2/12',
    'vietnamese bakery on 1/25'
]
const wearablesRewards = [
    'athletic shoes',
    'thrift store',
    'lip stain and mascara'
]

function categorizeReward(reward: StatsData.Reward) {
    if (reward.rewardType === 'Childhood photo') {
        return 'Childhood photos'
    }
    const rc = reward.comment.toLowerCase()
    const keywords = {
        taxi: ['lyft', 'taxi', 'cab'],
        art: ['art ', 'framed'],
        food: ['food', 'breakfast', 'lunch', 'dinner', 'poke', 'coffee', 'tea', 'cafeteria', 'grocery'],
        travel: ['flight', 'checked bag'],
        wearables: ['dress', 'earrings', 'shirt']
    }
    const matchesKeyword = (category: keyof typeof keywords) => {
        return keywords[category].some(keyword => rc.includes(keyword))
    }
    if (matchesKeyword('taxi')) {
        return 'Taxis'
    } else if (
        reward.rewardType === 'Craft supplies ($)' ||
        artRewards.includes(rc) ||
        matchesKeyword('art')
    ) {
        return 'Art supplies'
    } else if (foodRewards.includes(rc) || matchesKeyword('food')) {
        return 'Food'
    } else if (rc.includes('massage')) {
        return 'Massages'
    } else if (matchesKeyword('travel')) {
        return 'Travel'
    } else if (wearablesRewards.includes(rc) || matchesKeyword('wearables')) {
        return 'Wearables'
    }

    return 'Other'
}