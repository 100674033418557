import React, { ChangeEvent } from 'react'

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Switch,
    VStack,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

import { baseUrl } from '../env'
import dayjs from '../dayjs'
import ReminderFormTime from './ReminderFormTime'
import ReminderFormDatetime from './ReminderFormDatetime'

function ReminderForm() {
    const adminView = window.localStorage.getItem('adminView') === 'true'
    const [notifyDomOnFailure, setNotifyDomOnFailure] = React.useState(false)
    const [notifyDomOnSuccess, setNotifyDomOnSuccess] = React.useState(adminView ? false : null)

    const [reminderTitle, setReminderTitle] = React.useState('')
    const [reminderDescription, setReminderDescription] = React.useState('')
    const handleReminderTitleChange = (event: ChangeEvent<HTMLInputElement>) => setReminderTitle(event.currentTarget.value)
    const handleReminderDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => setReminderDescription(event.currentTarget.value)

    const [notificationTime, setNotificationTime] = React.useState(dayjs().add(15, 'minutes').toISOString())
    const [deadlineTime, setDeadlineTime] = React.useState(dayjs().add(30, 'minutes').toISOString())

    const [isLoading, setIsLoading] = React.useState(false)
    const [didError, setDidError] = React.useState(false)
    const [didSucceed, setDidSucceed] = React.useState(false)

    const [section, setSection] = React.useState('time')

    const handleButtonPress = async () => {
        setIsLoading(true)
        const response = await fetch(`${baseUrl}/tasks/reminders`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                taskName: reminderTitle,
                taskDescription: reminderDescription,
                reminderTime: notificationTime,
                deadline: deadlineTime,
                notifyDomOnFailure,
                notifyDomOnSuccess: notifyDomOnSuccess || false
            })
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
            setReminderTitle('')
            setReminderDescription('')
        } else {
            setDidError(true)
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    const settings = JSON.parse(window.localStorage.getItem('settings') || '{}')
    const placeholder = settings.censored ?
        'Be nice to Sam\'s lovely girlfriend' :
        'Be nice to Sam\'s favorite possession'
    return (
        <VStack align='stretch'>
            <Card>
                <CardHeader style={{ paddingBottom: '0px' }}>
                    <Heading size="md">Choose reminder text</Heading>
                </CardHeader>
                <CardBody>
                    Reminder title: <Input value={reminderTitle} onChange={handleReminderTitleChange} placeholder={placeholder} size='md' />
                    Reminder description (optional): <Input value={reminderDescription} onChange={handleReminderDescriptionChange} size='md' />
                <div /><br />
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='notify-dom-failure' mb='0'>
                        Notify Sam of missed deadline
                    </FormLabel>
                    <Switch
                        id='notify-dom-failure'
                        onChange={e => setNotifyDomOnFailure(e.target.checked)}
                    />
                </FormControl>
                { adminView ? 
                <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='notify-dom-success' mb='0'>
                        Notify me on success
                    </FormLabel>
                    <Switch
                        id='notify-dom-success'
                        onChange={e => setNotifyDomOnSuccess(e.target.checked)}
                    />
                </FormControl> : null
                }
                </CardBody>
            </Card>
            <Card>
                <CardHeader style={{ paddingBottom: '0px' }}>
                <Menu>
                    <MenuButton
                        px={4}
                        py={2}
                        transition='all 0.2s'
                        borderRadius='md'
                        borderWidth='1px'
                        _hover={{ bg: 'gray.400' }}
                        _expanded={{ bg: 'blue.400' }}
                        _focus={{ boxShadow: 'outline' }}
                    >
                        <HStack>
                            <Heading size="md">
                                {section === 'time' ?
                                    'Set reminder time' :
                                    section === 'datetime' ?
                                    'Set reminder date and time' :
                                    'Set undated reminder'
                                }
                            </Heading>
                            <ChevronDownIcon />
                        </HStack>
                    </MenuButton>
                    <MenuList>
                        <MenuItem
                            onClick={() => setSection('time')}
                        >Set reminder time</MenuItem>
                        <MenuItem
                            onClick={() => setSection('datetime')}
                        >Set reminder date and time</MenuItem>
                    </MenuList>
                </Menu>
                </CardHeader>
                { section === 'time' ?
                    <ReminderFormTime
                        notificationTime={notificationTime}
                        setNotificationTime={setNotificationTime}
                        deadlineTime={deadlineTime}
                        setDeadlineTime={setDeadlineTime}
                    /> : 
                    <ReminderFormDatetime
                        notificationTime={notificationTime}
                        setNotificationTime={setNotificationTime}
                        deadlineTime={deadlineTime}
                        setDeadlineTime={setDeadlineTime}
                    />
                }
            </Card>
            <Button
                isDisabled={!reminderTitle.length}
                isLoading={isLoading}
                colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
                onClick={handleButtonPress}
            >
                {didSucceed ? 'Created reminder!' : didError ? 'Failed to create reminder, let Sam know :(' : 'Create reminder' }
            </Button>
        </VStack>
    )
}

export default ReminderForm