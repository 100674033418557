import { useState } from 'react'

import {
    Button,
    Center,
    FormLabel,
    HStack,
    Select,
    Stack,
    Switch,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'

import { baseUrl } from '../env'

import { IsoTime } from '../types/branded'
import { Task } from '../types/models'

import 'react-datepicker/dist/react-datepicker.css'

export default function AdminAddForm({ tasks }: { tasks: Task[] }) {
    const [deadline, setDeadline] = useState(null as IsoTime | null)
    const [useStandardDeadline, setUseStandardDeadline] = useState(false)

    const handleSubmit = async () => {
        const response = await fetch(`${baseUrl}/handlers/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                taskId: (document.getElementById('task') as HTMLSelectElement).value,
                deadline,
                useStandardDeadline
            })
        })

        if (response.ok) {
            setDeadline(null)
        }
    }

    return (
        <Center>
            <Stack
                direction="column"
                spacing={4}
                align="center"
                justify="center"
                p={4}
                borderRadius="md"
                boxShadow="md"
            >
                <Select id='task'>
                    <option value=''>Select a task</option>
                    {tasks.map((task: Task) => (
                        <option key={task.id} value={task.id}>{task.name}</option>
                    ))}
                </Select>
                <HStack>
                    <FormLabel htmlFor='useStandardDeadline' mb='0'>
                        Use standard deadline
                    </FormLabel>
                    <Switch
                        id='recurring'
                        onChange={e => setUseStandardDeadline(e.target.checked)}
                        isChecked={useStandardDeadline}
                        isDisabled={!deadline || tasks.find(
                            task => task.id === (
                                document.getElementById('task') as HTMLSelectElement
                            ).value
                        )?.standardDeadline === null}
                        style={{ marginLeft: '115px' }}
                    />
                </HStack>
                <br />
                <DatePicker
                    selected={deadline ? new Date(deadline) : null}
                    onChange={(dateObj) => {
                        setDeadline(dateObj!.toISOString() as IsoTime)
                    }}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showTimeInput
                    inline
                />
                <Button
                    isDisabled={!deadline}
                    colorScheme='blue'
                    onClick={handleSubmit}
                    >
                    Add occurrence
                </Button>
            </Stack>
        </Center>
    )
}