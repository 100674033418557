import { baseUrl } from '../env'

const friendlyLocationMap = {
    '45.537, -122.668': 'My apartment',
    '45.592, -122.735': 'Her house'
} as Record<string, string>

const locationTranslations = {
    'My apartment': "Sam's apartment",
    'Her house': 'Your house'
}

export const getFriendlyLocationForSub = (location: string | null) => {
    // @ts-ignore
    return location && (locationTranslations[location] || location)
}

const getFriendlyLocation = (position: GeolocationPosition) => {
    const lat = position.coords.latitude.toFixed(3)
    const lng = position.coords.longitude.toFixed(3)
    return friendlyLocationMap[lat + ', ' + lng] || null
}

export const positionHandler = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords

    fetch(`${baseUrl}/adhoc`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'location',
        json: {
          latitude: Number(latitude.toFixed(4)),
          longitude: Number(longitude.toFixed(4)),
          friendlyLocation: getFriendlyLocation(position),
        },
      })
    })
}

export const manualPositionHandler = (position: GeolocationPosition, comment: string, addedByDom: boolean) => {
    const { latitude, longitude } = position.coords

    return fetch(`${baseUrl}/adhoc`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'location-manual',
        text: comment,
        json: {
          latitude: Number(latitude.toFixed(4)),
          longitude: Number(longitude.toFixed(4)),
          friendlyLocation: getFriendlyLocation(position),
          addedByDom,
        },
      })
    })
    .then(response => response.ok)
}

export const positionErrorHandler = (error: GeolocationPositionError) => {
    fetch(`${baseUrl}/adhoc`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'location-error',
        json: {
          type: error.code === 1 ? 'Permission denied' :
            error.code === 2 ? 'Position unavailable' : 'Timeout',
          message: error.message,
        },
      })
    })
  }