import { useState } from 'react'

import { baseUrl } from '../env'

import {
    Button,
    Center,
    Flex,
    HStack,
    Input,
    Stack,
    Text,
} from '@chakra-ui/react'

export default function AdminConsequencesForm() {
    const [comment, setComment] = useState('')
    const [quantity, setQuantity] = useState(1)

    const makeHandleSubmit = (type: 'reward'|'punishment') => async () => {
        const response = await fetch(`${baseUrl}/${type}s`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                comment,
                quantity,
            })
        })

        if (response.ok) {
            setComment('')
            setQuantity(1)
        }
    }

    return (
        <Center>
            <Stack
                direction="column"
                spacing={4}
                align="center"
                justify="center"
                p={4}
                borderRadius="md"
                boxShadow="md"
            >
                <Input
                    placeholder="Comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                <Flex
                    align="center"
                    justify="center"
                    w="100%"
                >
                    <Text>Quantity:</Text>
                    <Input
                        type="number"
                        value={quantity}
                        onChange={(e) => setQuantity(+e.target.value)}
                        w="50%"
                        ml={2}
                    />
                </Flex>
                <HStack>
                    <Button
                        onClick={makeHandleSubmit('reward')}
                        colorScheme="green"
                    >
                        Reward
                    </Button>
                    <Button
                        onClick={makeHandleSubmit('punishment')}
                        colorScheme="red"
                    >
                        Punish
                    </Button>
                </HStack>
            </Stack>
        </Center>
    )
}