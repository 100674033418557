import { useState } from 'react'

import { baseUrl } from '../env'

import {
    Button,
    Center,
    Flex,
    Input,
    Select,
    Stack,
    Text,
} from '@chakra-ui/react'

import { Task } from '../types/models'

export default function AdminIncentivizeForm({ tasks }: { tasks: Task[] }) {
    const [rewardQuantity, setRewardQuantity] = useState(0)
    const [punishmentQuantity, setPunishmentQuantity] = useState(0)

    const handleSubmit = async () => {
        const response = await fetch(`${baseUrl}/handlers/incentivize`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                taskId: (document.getElementById('task') as HTMLSelectElement).value,
                rewardQuantity,
                punishmentQuantity
            })
        })

        if (response.ok) {
            setRewardQuantity(0)
            setPunishmentQuantity(0)
        }
    }

    return (
        <Center>
            <Stack
                direction="column"
                spacing={4}
                align="center"
                justify="center"
                p={4}
                borderRadius="md"
                boxShadow="md"
            >
                <Select id='task'>
                    <option value=''>Select a task</option>
                    {tasks.map((task: Task) => (
                        <option key={task.id} value={task.id}>{task.name}</option>
                    ))}
                </Select>
                <Flex
                    align="center"
                    justify="center"
                    w="100%"
                >
                    <Text>Reward quantity:</Text>
                    <Input
                        type="number"
                        value={rewardQuantity}
                        onChange={(e) => setRewardQuantity(+e.target.value)}
                        w="50%"
                        ml={2}
                    />
                </Flex>
                <Flex
                    align="center"
                    justify="center"
                    w="100%"
                >
                    <Text>Punishment quantity:</Text>
                    <Input
                        type="number"
                        value={punishmentQuantity}
                        onChange={(e) => setPunishmentQuantity(+e.target.value)}
                        w="50%"
                        ml={2}
                    />
                </Flex>
                <Button
                    isDisabled={!rewardQuantity && !punishmentQuantity}
                    colorScheme='blue'
                    onClick={handleSubmit}
                    >
                    Incentivize
                </Button>
            </Stack>
        </Center>
    )
}