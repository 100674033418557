import {
  useState
} from 'react'
  
import {
  Button,
  Card,
  CardBody,
  Select,
} from '@chakra-ui/react'

import { baseUrl } from '../env'
import dayjs from '../dayjs'

import type { TaskOccurrence } from '../types/models'

export default function AdminUncompleteForm({ taskOccurrences }: { taskOccurrences: TaskOccurrence[] }) {
    const [isLoading, setIsLoading] = useState(false)
    const [didSucceed, setDidSucceed] = useState(false)
    const [didError, setDidError] = useState(false)

    const filteredOccurrences = taskOccurrences.filter(occurrence => occurrence.completedAt)

    const handleButtonPress = async () => {
      setIsLoading(true)
      const response = await fetch(`${baseUrl}/handlers/uncomplete`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            occurrenceId: (document.getElementById('occurrence') as HTMLSelectElement).value
          })
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
        } else {
            setDidError(true)
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
      <Card>
        <CardBody>
        <Select id='occurrence'>
            <option value=''>Select an occurrence</option>
            {filteredOccurrences.map(occurrence => (
                <option
                  key={occurrence.id}
                  value={occurrence.id}
                >
                  {`${occurrence.task.name} (${dayjs(occurrence.date).format('ddd, M/D')}) - ${occurrence.resolutionState}`}
                </option>
            ))}
        </Select>
        </CardBody>
        <Button
          isLoading={isLoading}
          colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
          onClick={handleButtonPress}
        >
          {didSucceed ? 'Uncompleted task' : didError ? 'Failed to uncomplete task' : 'Uncomplete task' }
        </Button>
      </Card>
    )
}