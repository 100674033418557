import _ from 'lodash'
import {
    Button,
    Flex,
} from '@chakra-ui/react'

import { baseUrl } from '../env'
import dayjs from '../dayjs'

import { IsoTime } from '../types/branded'
import { TaskOccurrenceWithHistory } from '../types/models'

const makeHandler = (handlerName: string) => {
    const fn = async function(occurrence: TaskOccurrenceWithHistory, updateOccurrences: () => void ) {
        await fetch(`${baseUrl}/handlers/${handlerName}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ occurrenceId: occurrence.id })
        })
        await updateOccurrences()
    }

    return _.debounce(fn, 2000, { leading: true, trailing: false })
}

const retcon = makeHandler('retcon')
const cancel = makeHandler('cancel')
const grantMercy = makeHandler('mercy')
const grantGrace = makeHandler('grace')
const requestIncentivize = makeHandler('incentivize')

export default function TaskOccurrenceCardButtons({
  occurrence,
  updateOccurrences,
  adminView,
  isPleading,
  setIsPleading,
  setLevoRetconTime,
}:
  {
    occurrence: TaskOccurrenceWithHistory,
    updateOccurrences: () => void,
    adminView: boolean,
    isPleading: boolean,
    setIsPleading: (isPleading: boolean) => void
    setLevoRetconTime: (levoRetconTime: IsoTime) => void
  }) {
    const { resolutionState, history: { hasRewardIncentive, hasPunishmentIncentive } } = occurrence
    // This also implicitly handles reminders
    const hasIncentive = hasRewardIncentive || hasPunishmentIncentive

    const retconButton = <Button
      onClick={(event) => {
        event.stopPropagation();
        if (occurrence.task.name === 'Levothyroxine') {
          // Semi-arbitrary time to kick off time picking
          setLevoRetconTime(dayjs().subtract(30, 'minute').toISOString())
        } else {
          retcon(occurrence, updateOccurrences)
        }
      }}
      style={{
        backgroundColor: '#2222ff',
        color: 'white',
        zIndex: 1000,
      }}
    >
      Retcon
    </Button>

    const cancelButton = <Button
      onClick={(event) => { event.stopPropagation(); cancel(occurrence, updateOccurrences) }}
      style={{
        backgroundColor: '#000000',
        color: 'white',
        zIndex: 1000,
      }}
    >
      Cancel
    </Button>

    const settings = JSON.parse(window.localStorage.getItem('settings') || '{}')
    const pleadButton = <Button
      onClick={(event) => { event.stopPropagation(); setIsPleading(!isPleading) }}
      backgroundColor='blue.200'
      style={{
        color: 'black',
        zIndex: 1000,
      }}
    >
      {settings.censored ? 'Excuse' : 'Plead'}
    </Button>

    const incentivizeButton = <Button
      onClick={(event) => { event.stopPropagation(); requestIncentivize(occurrence, updateOccurrences) }}
      backgroundColor='green.200'
      style={{
        color: 'black',
        zIndex: 1000,
      }}
    >
      Incentivize
    </Button>

    const mercyButton = <Button
      onClick={(event) => { event.stopPropagation(); grantMercy(occurrence, updateOccurrences) }}
      backgroundColor='blue.200'
      style={{
        color: 'black',
        zIndex: 1000,
      }}
    >
      Mercy
    </Button>

    const graceButton = <Button
        onClick={(event) => { event.stopPropagation(); grantGrace(occurrence, updateOccurrences) }}
        backgroundColor='blue.300'
        style={{
        color: 'black',
        zIndex: 1000,
    }}
    >
        Grace
    </Button>

    const includeRetcon = (
      occurrence.task.name === 'Levothyroxine' &&
      !occurrence.completedAt &&
      !adminView
    ) || (hasPunishmentIncentive &&
        ['failed', 'completed-late'].includes(resolutionState))
    const includePlead = hasPunishmentIncentive && !adminView &&
        ['failed', 'completed-late'].includes(resolutionState) &&
        !occurrence.justification
    const includeIncentivize = !hasIncentive && !adminView && 
        resolutionState === 'pending'
    const includeCancel = !hasIncentive || (adminView &&
        !['partial-grace', 'retcon', 'mercy'].includes(resolutionState))
    const includeMercy = adminView && hasPunishmentIncentive &&
        !['pending', 'partial-grace', 'retcon', 'mercy'].includes(resolutionState)
    const includeGrace = hasIncentive && adminView

    return <Flex justifyContent='space-between' flexDirection='row-reverse'>
        {includeCancel ? cancelButton : null}
        {includeRetcon ? retconButton : null}
        {includePlead  ? pleadButton : null}
        {includeIncentivize ? incentivizeButton : null}
        {includeMercy  ? mercyButton  : null}
        {includeGrace  ? graceButton  : null}
    </Flex>
}
