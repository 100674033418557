import React, { ChangeEvent } from 'react'

import {
    Button,
    Card,
    CardBody,
    FormLabel,
    HStack,
    Input,
    Switch,
    VStack,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'

import { baseUrl } from '../env'
import dayjs from '../dayjs'

import 'react-datepicker/dist/react-datepicker.css'

const selectedDaysNone = {
    'Mon': false,
    'Tue': false,
    'Wed': false,
    'Thu': false,
    'Fri': false,
    'Sat': false,
    'Sun': false
}

function AdminTaskForm() {
    const [notifyDomOnSuccess, setNotifyDomOnSuccess] = React.useState(true)
    const [notifyDomOnFailure, setNotifyDomOnFailure] = React.useState(true)
    const [persist, setPersist] = React.useState(true)
    const [isRecurring, setIsRecurring] = React.useState(false)
    const [sendSpecialNotification, setSendSpecialNotification] = React.useState(false)
    const [suppressOccurrence, setSuppressOccurrence] = React.useState(false)

    const [selectedDays, setSelectedDays] = React.useState<{ [day: string]: boolean }>(selectedDaysNone)

    const [taskTitle, setTaskTitle] = React.useState('')
    const [taskDescription, setTaskDescription] = React.useState('')
    const [standardDeadline, setStandardDeadline] = React.useState('23:59')
    const [rewardsValue, setRewardsValue] = React.useState(0)
    const [punishmentsValue, setPunishmentsValue] = React.useState(0)
    const handleTaskTitleChange = (event: ChangeEvent<HTMLInputElement>) => setTaskTitle(event.currentTarget.value)
    const handleTaskDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => setTaskDescription(event.currentTarget.value)
    const handleStandardDeadlineChange = (event: ChangeEvent<HTMLInputElement>) => setStandardDeadline(event.currentTarget.value)
    const handleRewardsValueChange = (event: ChangeEvent<HTMLInputElement>) => setRewardsValue(parseInt(event.currentTarget.value) || 0)
    const handlePunishmentsValueChange = (event: ChangeEvent<HTMLInputElement>) => setPunishmentsValue(parseInt(event.currentTarget.value) || 0)

    const [deadlineTime, setDeadlineTime] = React.useState(dayjs().toISOString())
    const [notificationTime, setNotificationTime] = React.useState(dayjs().toISOString())
    const [notificationText, setNotificationText] = React.useState('')

    const [isLoading, setIsLoading] = React.useState(false)
    const [didError, setDidError] = React.useState(false)
    const [didSucceed, setDidSucceed] = React.useState(false)

    const handleButtonPress = async () => {
        setIsLoading(true)
        const selectedDaysArr = Object.entries(selectedDays)
        .filter(([_, value]) => value).map(([day, _]) => day)
        const response = await fetch(`${baseUrl}/tasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: taskTitle,
                description: taskDescription,
                isRecurring,
                isPersistent: persist,
                notifyDomOnFailure,
                notifyDomOnSuccess,
                standardDeadline: isRecurring ? standardDeadline : null,
                schedule: isRecurring ? selectedDaysArr : null,
                reminderSchedule: isRecurring ? undefined : null,
                // Not Task properties
                notificationTime: sendSpecialNotification ? notificationTime : null,
                notificationText: notificationText || null,
                deadline: isRecurring || suppressOccurrence ? null : deadlineTime,
                rewards: rewardsValue,
                punishments: punishmentsValue,
                suppressOccurrence
            })
        })
        setIsLoading(false)
        if (response.ok) {
            setDidSucceed(true)
            setTaskTitle('')
            setTaskDescription('')
        } else {
            setDidError(true)
        }
        setTimeout(() => {
            setDidError(false)
            setDidSucceed(false)
        }, 5000)
    }

    return (
        <VStack align='stretch'>
            <Card>
                <CardBody>
                    Title: <Input value={taskTitle} onChange={handleTaskTitleChange} size='md' />
                    Description: <Input value={taskDescription} onChange={handleTaskDescriptionChange} size='md' />
                <div /><br />
                <HStack>
                    <FormLabel htmlFor='recurring' mb='0'>
                        Is recurring
                    </FormLabel>
                    <Switch
                        id='recurring'
                        onChange={e => setIsRecurring(e.target.checked)}
                        isChecked={isRecurring}
                        style={{ marginLeft: '115px' }}
                    />
                </HStack>
                <HStack>
                    <FormLabel htmlFor='notify-dom-success' mb='0'>
                        Notify on completion
                    </FormLabel>
                    <Switch
                        id='notify-dom-success'
                        onChange={e => setNotifyDomOnSuccess(e.target.checked)}
                        isChecked={notifyDomOnSuccess}
                        style={{ marginLeft: '45px' }}
                    />
                </HStack>
                <HStack>
                    <FormLabel htmlFor='notify-dom-failure' mb='0'>
                        Notify on missed deadline
                    </FormLabel>
                    <Switch
                        id='notify-dom-failure'
                        onChange={e => setNotifyDomOnFailure(e.target.checked)}
                        isChecked={notifyDomOnFailure}
                    />
                </HStack>
                <HStack>
                    <FormLabel htmlFor='persist' mb='0'>
                        Persist if not completed
                    </FormLabel>
                    <Switch
                        id='persist'
                        onChange={e => setPersist(e.target.checked)}
                        isChecked={persist}
                        style={{ marginLeft: '24px' }}
                    />
                </HStack>
                {!isRecurring && <HStack>
                    <FormLabel htmlFor='suppress-occurrence' mb='0'>
                        Suppress occurrence
                    </FormLabel>
                    <Switch
                        id='suppress-occurrence'
                        onChange={e => setSuppressOccurrence(e.target.checked)}
                        style={{ marginLeft: '42px' }}
                    />
                </HStack>}
                {!isRecurring && <HStack>
                    <FormLabel htmlFor='special-notification-toggle' mb='0'>
                        Send special notification
                    </FormLabel>
                    <Switch
                        id='special-notification-toggle'
                        onChange={e => setSendSpecialNotification(e.target.checked)}
                        style={{ marginLeft: '19px' }}
                    />
                </HStack>}
                <br />
                {isRecurring ?
                    <div>
                    <HStack marginTop='10px' marginBottom='10px'>
                        {Object.keys(selectedDays).map(day => (
                            <Button
                                key={day}
                                colorScheme={selectedDays[day] ? 'green' : 'gray'}
                                onClick={() => setSelectedDays({ ...selectedDays, [day]: !selectedDays[day] })}
                            >
                                {day}
                            </Button>
                        ))}
                    </HStack>
                    Standard deadline: <Input value={standardDeadline} onChange={handleStandardDeadlineChange} size='md' />
                    </div> :
                    <VStack>
                    { sendSpecialNotification ? <Input value={notificationText} onChange={e => setNotificationText(e.currentTarget.value)} placeholder='Notification text' size='md' /> : null }
                    <HStack>
                        <DatePicker
                            selected={new Date(deadlineTime)}
                            onChange={(date) => {
                                setDeadlineTime(dayjs(date!).toISOString())
                            }}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            showTimeInput
                            inline
                        />
                        {sendSpecialNotification ?
                        <DatePicker
                            selected={new Date(notificationTime!)}
                            onChange={(date) => {
                                setNotificationTime(dayjs(date!).toISOString())
                            }}
                            dateFormat="MMMM d, yyyy h:mm aa"
                            showTimeInput
                            inline
                        /> : null}
                    </HStack>
                    </VStack>
                }
                <br />
                Rewards: <Input value={rewardsValue} onChange={handleRewardsValueChange} placeholder='Reward' size='md' />
                Punishments: <Input value={punishmentsValue} onChange={handlePunishmentsValueChange} placeholder='Punishment' size='md' />
                </CardBody>
            </Card>
            <Button
                isDisabled={!taskTitle.length}
                isLoading={isLoading}
                colorScheme={didSucceed ? 'green' : didError ? 'red' : 'blue'}
                onClick={handleButtonPress}
            >
                {didSucceed ? 'Created task!' : didError ? 'Failed to create task' : 'Create task' }
            </Button>
        </VStack>
    )
}

export default AdminTaskForm